/** @format */

@use "../../App.scss";

.startScreen {
  height: 100vh;
  width: 100%;
  cursor: pointer;

  .infoContainer {
    // opacity: 0;
    position: absolute;
    top: 48%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    text-align: center;
    font-size: 25px;
    letter-spacing: 4.5px;
    color: white;
    text-shadow: 1px 2px 1px App.$purple;
    // animation: fadeIn 1s linear forwards;
    // animation-delay: 2.5s;
    @keyframes fadeIn {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }

    .infoTitle {
      // position: relative;
      // font-weight: 700;
      // text-shadow: 2px 2px 4px App.$perfect-pink;
      img {
        height: 50px;
        // position: absolute;
        // top: 50%;
        // transform: translate(-15%, -50%);
      }
    }

    .subInfo {
      letter-spacing: 3px;
      div:nth-child(1) {
        font-size: 14px;
        font-weight: 700;
        text-shadow: 2px 2px 4px App.$perfect-pink;
        margin: 12px 0 12px;
      }
      div:nth-child(2),
      div:nth-child(3) {
        font-size: 12px;
        font-weight: 700;
        text-shadow: none;
      }
    }

    .content {
      letter-spacing: 3px;
      margin: 21px 0 0 0;
      div:nth-child(1) {
        font-size: 14px;
        font-weight: 700;
        text-shadow: 2px 2px 4px App.$perfect-pink;
      }
      div:nth-child(2) {
        font-size: 12px;
        margin: 7px 0 0 0;
        // letter-spacing: 3.8px;
        font-weight: 600;
        text-shadow: none;
      }
    }

    .addOnContent {
      letter-spacing: 3px;
      margin: 21px 0 0 0;
      div:nth-child(1) {
        font-size: 14px;
        font-weight: 1000;
        color: white;
        text-shadow: 1.5px 1px 2px App.$perfect-pink;
      }
      div:nth-child(2) {
        font-size: 14px;
        margin: 7px 0 0 0;
        font-weight: 1000;
        color: white;
        text-shadow: 1.5px 1px 2px App.$perfect-pink;
      }
    }
  }

  .startScreen_CTA {
    position: absolute;
    bottom: 6.3vh;
    width: 100%;
    text-align: center;
    font-size: 18px;
    letter-spacing: 4.5px;
    animation: glowing 3s ease infinite;
    color: App.$purple;
    font-weight: 700;
    text-shadow: 2px 2px 1px App.$light-pink;
  }

  .boxContainer {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100px;
    height: 200px;
    animation: boxDisapear 3s forwards ease-out;

    img:nth-child(1) {
      position: absolute;
      width: 100px;
      top: 0px;
      z-index: 2;
      transform: rotate(35deg);
    }

    img:nth-child(2) {
      position: absolute;
      width: 100px;
      top: 20px;
      left: 2px;
      z-index: 1;
      transform: rotate(15deg);
    }

    .headAnimate {
      animation: openBox 1.6s ease-in-out forwards;
    }
  }

  .heartContainer {
    img {
      // top: 39%;
      // left: 50%;
      position: absolute;
      width: 35px;
    }
  }

  .blink {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    z-index: 2;
    animation: glowing 3s ease-in-out infinite;
  }
}

@keyframes openBox {
  0% {
    transform: rotate(35deg);
  }
  100% {
    transform: rotate(0) translate(-20px, -30px);
  }
}

@keyframes boxDisapear {
  0% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes glowing {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
