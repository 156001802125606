@use "../../App.scss";

.blockedScreen {
  height: 100vh;
  width: 100vw;
  background: rgb(0, 0, 0);
  color: white;

  .title,
  .description {
    position: absolute;
    width: 100%;
    text-align: center;
    animation: glowing 4s ease-out infinite;
  }

  .title {
    bottom: 80px;
    font-weight: 600;
    font-size: 25px;
  }

  .description {
    bottom: 50px;
    font-weight: 400;
    font-size: 15px;
    box-sizing: border-box;
    padding: 0 20px 0 20px;
  }
}

@keyframes glowing {
  0% {
    opacity: 0.6;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.6;
  }
}
