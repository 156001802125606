@use "../../App.scss";

$purple: #a19fd7;
$light-purple: #e0c3ff;
$white: #fdf2f8;
$pink: #f9a8d4;
$light-pink: #fbcfe8;

.result {
  width: 100%;
  max-height: calc(100 * var(--vh));
  overflow: hidden;

  .result_header {
    width: 100%;
    height: calc(10 * var(--vh));
    max-height: calc(10 * var(--vh));
    box-sizing: border-box;

    .header_left {
      background-color: $light-purple;

      .title,
      .name {
        position: absolute;
        // left: 50%;
        // transform: translateX(-50%);
        width: 100%;
        text-align: center;
        z-index: 2;
      }

      .title {
        bottom: 4.65vh;
        font-size: 12px;
      }

      .name {
        bottom: 2vh;
        font-size: 14px;
        font-weight: 600;
      }

      .decorArrow {
        position: absolute;
        font-size: 35px;
        color: white;
        opacity: 0.35;
        top: 26%;
        transform: translateY(-50%);
        z-index: 1;
      }

      .pos1 {
        left: 11%;
      }
      .pos2 {
        left: 45%;
      }
      .pos3 {
        left: 79%;
      }
    }

    .header_right {
      height: 100%;
      overflow: hidden;
      background-color: $light-pink;

      .favIcon {
        position: absolute;
        height: 150%;
        top: 55%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 0.45;
      }

      .character {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        font-weight: 800;
        text-transform: uppercase;
        text-align: center;
        font-size: 14.5px;
        color: black;
      }
    }
  }
  .logo {
    background-color: $purple;
    box-sizing: border-box;
    height: calc(4.5 * var(--vh));
    max-height: calc(4.5 * var(--vh));
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      filter: drop-shadow(1px 1px 1px App.$purple);
      margin: 0 8px 0 8px;
    }
    img:nth-child(1) {
      height: 100%;
    }
    img:nth-child(2) {
      height: 70%;
    }
  }

  .result_body {
    height: calc(24 * var(--vh));
    max-height: calc(24 * var(--vh));
    box-sizing: border-box;
    background-color: $pink;

    .heartContainer {
      .heart {
        position: absolute;
        color: App.$perfect-pink;
        font-size: 17px;
        opacity: 0;
        animation: heartAnimate 4s linear infinite;
        transition: all 0.12s;
        overflow: hidden;
      }

      @keyframes heartAnimate {
        0% {
          opacity: 0;
        }
        10% {
        }
        50% {
          opacity: 1;
        }
        100% {
          opacity: 0;
          top: 0;
        }
      }
    }
    .character_image_container {
      height: 100%;
      width: 100%;
      overflow: hidden;
      padding: 1px 1px 1px 1.5px;
      box-sizing: border-box;
      clip-path: polygon(
        20% 0%,
        80% 0%,
        100% 20%,
        100% 80%,
        80% 100%,
        20% 100%,
        0% 80%,
        0% 20%
      );
      filter: drop-shadow(0 0 5px App.$light-pink);

      .border {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        margin: 0;
        background-color: $white;
        clip-path: polygon(
          20% 0%,
          80% 0%,
          100% 20%,
          100% 80%,
          80% 100%,
          20% 100%,
          0% 80%,
          0% 20%
        );

        .ownCharacterImg {
          width: 70%;
          filter: drop-shadow(0 0 5px App.$light-pink);
        }
      }
    }

  }

  .result_body2 {
    height: calc(28 * var(--vh));
    max-height: calc(28 * var(--vh));
    box-sizing: border-box;

    .title {
      background-color: $purple;
      // background-color: App.$unselected-answer-hover;
      width: 100%;
      height: 35px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px;
      font-size: 16px;
      font-weight: 600;
      color: white;
      text-shadow: 1px 1.2px 2px grey;
    }

    .behaveCont {
      display: flex;
      justify-content: center;
      align-items: center;
      height: calc(100% - 35px);
      width: 100%;
      box-sizing: border-box;
      padding: 5px;
      background-color: $white;
      // background-color: App.$light-red;


      .behavior {
        margin: 5px;
        // background-color: App.$unselected-answer-hover;
        box-shadow: 1.5px 1.5px 3px $purple;
        background-color: $purple;
        font-weight: 500;
        font-size: 12.5px;
        color: white;
        padding: 5px;
        border-radius: 8px;
      }
    }
  }

  .matchesCharacter {
    height: calc(26 * var(--vh));
    max-height: calc(26 * var(--vh));
    overflow: hidden;

    .attract,
    .anti {
      .title {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        font-size: 15px;
        font-weight: 600;
      }

      .characters {
        width: 100%;
        height: calc(100% - 23px);
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .anti {
      .title {
        background-color: $purple;
        color: white;
        padding: 3px;
      }

      .characters {
        background-color: $light-purple;
      }
    }

    .attract {
      .title {
        background-color: $pink;
        color: white;
        padding: 3px;
      }

      .characters {
        background-color: $light-pink;
      }
    }
  }

  .btnsContainer {
    height: calc(5 * var(--vh));
    max-height: calc(5 * var(--vh));
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $purple;

    .icon {
      margin: 0 5px 0 0;
    }

    .restart {
      color: white;
      background-color: App.$perfect-pink;
      font-weight: 600;
      padding: 5px 8px 5px 8px;
      border: none;
      outline: none;
      border-radius: 5px;
      transition: all 0.25s ease-in-out;
      cursor: pointer;
    }

    .restart:hover {
      background-color: #fda4af;
    }
  }

  .result_footer {
    height: calc(3 * var(--vh));
    max-height: calc(3 * var(--vh));
    box-sizing: border-box;
    min-width: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: App.$unselected-answer-hover;

    .copyright {
      font-size: 11px;
      color: black;
    }
  }
}

.characterDisplay {
  margin: 0 0 0 0;
  width: 120px;

  .imageContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .characterImage {
      width: 40px;
      margin: 0 0 5px 0;
    }
  }

  .characterName {
    width: 100%;
    text-align: center;
    font-size: 12px;
    font-weight: 600;
  }
}

.customBtn {
  margin: 0 0 0 8px;
  padding: 4.5px;
  background-color: white;
  border-radius: 5px;
  border: 1px solid rgb(84, 84, 84);
  font-size: 13px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.customBtn:hover {
  color: App.$lovely-pink;
  border: 1px solid App.$lovely-pink;
}

.resetColor {
  background-color: App.$lovely-pink;
  outline: none;
  border: none;
  color: white;
  padding: 5px;
  border-radius: 4px;
  // position: absolute;
  text-align: center;
  font-weight: 600;
  margin: 30px 0 0 0;
  width: 90px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.resetColor:hover {
  background-color: App.$lighter-pink;
  color: black;
}

@media screen and (max-height: 650px){
  .characterDisplay {
  
    .imageContainer {
  
      .characterImage {
        width: 40px;
      }
    }
  
    .characterName {
      width: 100%;
      text-align: center;
      font-size: 12px;
      font-weight: 600;
    }
  }
}
@media screen and (max-width: 350px){
  .behavior {
    margin: 53px;
    font-size: 11px;
    color: white;
    padding: 5px;
    border-radius: 8px;
  }
}

//4.5 + 13 + 35 + 43%
