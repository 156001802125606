@use "../../App.scss";

.rule {
  box-sizing: border-box;
  display: block;

  .rule {
    display: block;
    width: 100%;
    
  }

  .coverBtn {
    width: 100%;
    display: flex;
    justify-content: center;
    overflow: hidden;
    box-sizing: border-box;

    .rule_startBtn {
      border: none;
      width: 100%;
      background-color: App.$light-pink;
      color: rgb(255, 255, 255);
      font-size: 18px;
      text-shadow: 0.5px 0.5px 1px rgb(238, 236, 236);
      border-radius: 7px;
      margin: 30px 0 0 0;
      padding: 10px;
      letter-spacing: 1px;
      cursor: pointer;
      transition: all 0.15s ease;
    }

    .rule_startBtn:hover {
      background-color: App.$light-blue;
    }
  }
}
.ccfImg {
  height: 47%;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.8;
}
.question {
  height: 100vh;
  width: 100%;
  box-sizing: border-box;
  padding: calc(1vh + 60px) 0 0 0;

  .progressBar {
    position: absolute;
    width: 94%;
    height: 22px;
    border-radius: 7px;
    margin: 1vh 3% 2vh 3%;
    box-sizing: border-box;
    background-color: App.$unselected-answer;
    overflow: hidden;
    .progress {
      position: absolute;
      background-color: App.$light-pink;
      height: 100%;
      transition: all 0.12s linear;
      animation: changeColor 3.2s linear infinite;
      // width: 30px;
    }
  }

  .content {
    position: absolute;
    width: 100%;
    top: calc(3.5vh + 1vh + 60px);
    box-sizing: border-box;
    padding: 0 2% 0 0;
    margin: 12px 5px 5px 5px;
    text-align: center;
    text-shadow: 0.7px 0.7px 5px rgb(137, 137, 137);
    // background-color: white;
    // border-radius: 9px;
    // border: 1px solid black;
    // padding: 15px 0 15px 0;
    .content_QNum {
      font-size: 20px;
      font-weight: 700;
      margin: 0 0 10px 0;
      color: white;
      text-shadow: 1.5px 1.5px 15px App.$purple;
    }

    .content_question {
      line-height: 23px;
      font-size: 17px;
      font-weight: 500;
      color: white;
      text-shadow: 1.5px 1.5px 15px App.$purple;
    }
  }

  .answers {
    position: absolute;
    bottom: calc(3vh + 15px);
    list-style: none;
    width: 100%;
    padding: 0;

    .option {
      display: flex;
      align-items: center;
      padding: 10px;
      margin: 2.5vh 8% 2.5vh 8%;
      width: 80%;
      background-color: white;
      border-radius: 7px;
      cursor: pointer;
      transition: all 0.12s linear;
      opacity: 0;
      animation: fadeIn 0.2s linear forwards;
      box-shadow: 1px 1.7px 1px App.$purple;

      .answer_id {
        font-size: 20px;
        font-weight: 600;
        margin: 0 12px 0 0;
      }

      .answer_content {
        font-size: 15px;
      }
    }

    .option:hover {
      background-color: App.$unselected-answer-hover;
    }

    .option:nth-child(2) {
      animation-delay: 0.06s;
    }
    .option:nth-child(3) {
      animation-delay: 0.12s;
    }
    .option:nth-child(4) {
      animation-delay: 0.18s;
    }

    .selected,
    .selected:hover {
      background-color: App.$light-pink;
    }
    // Animation purpose
  }

  .nextBtn {
    position: absolute;
    bottom: 3vh;
    right: 8%;
    padding: 8px;
    font-size: 16px;
    outline: none;
    color: black;
    border: 1px solid black;
    border-radius: 8px;
    background-color: App.$unselected-answer-hover;
    cursor: pointer;
    transition: all 0.12s linear;
  }

  .nextBtn:not(.blockedSelect):hover {
    background-color: App.$light-blue;
  }

  .blockedSelect {
    cursor: not-allowed;
    opacity: 0.4;
  }

  .infoContainer {
    position: absolute;
    bottom: 3px;
    left: 50%;
    width: 100%;
    text-align: center;
    transform: translateX(-50%);
    opacity: 1;
    color: App.$unselected-answer;
    font-size: 11px;
  }
}

@keyframes changeColor {
  0% {
    background-color: App.$light-pink;
  }
  50% {
    background-color: App.$light-blue;
  }
  100% {
    background-color: App.$light-pink;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
