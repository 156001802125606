@import url("https://fonts.googleapis.com/css2?family=Alexandria:wght@400;500;510;600;700;800;900;1000&display=swap");

// Color
$light-blue: #9cc4fd;
$deep-blue: #3b82f6;
$light-pink: #e0c3ff;
$lovely-pink: #f9a8d4;
$lighter-pink: #f5d0fe;
$purple: #9376c7;
$yellow: #f0d48f;
$light-yellow: #eee4cc;
$red: #e13f54;
$light-red: #fecdd3;
$unselected-answer: #d1d5db;
$unselected-answer-hover: #f3f4f6;
$rose: #fda4af;
$light-rose: #fbcfe8;
$perfect-pink: #f472b6;
$perfect-pink-hover: #fbcfe8;

.ant-btn-primary {
  background-color: $perfect-pink;
}
.ant-btn-primary:hover {
  background-color: $perfect-pink-hover !important;
}

.ant-btn-default:hover {
  border: 1px solid $perfect-pink !important;
  color: $perfect-pink !important;
}

html,
body,
.App {
  font-family: "Alexandria", sans-serif;
  font-weight: 400; // Change the font weight as needed
  height: -webkit-fill-available;
  min-height: -webkit-fill-available;
  overflow: hidden;
  margin: 0;
  box-sizing: border-box;
  color: black;
}

body {
  background: url(../public/Assets/Images/background.png);
  background-size: cover;
}

img {
  pointer-events: none;
}

.unuseZone {
  // background-color: rgba(212, 210, 210, 0.081);
}

.gradient_purple_blue {
  background: linear-gradient(to right, $light-pink, $light-blue, $light-pink);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.gradient_green_yellow {
  background: linear-gradient(to right, #1dc2c2, #febb2e, #1dc2c2);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.restartBtns {
  position: absolute;
  outline: none;
  border: none;
  border-radius: 5px;
  padding: 10px;
}

.logoContainer {
  display: flex;
  position: absolute;
  align-items: center;
  top: 1.5vh;
  left: 2%;

  img {
    margin: 0 6px;
    filter: drop-shadow(1px 1px 1px $purple)
  }
  img:nth-child(1) {
    height: 52px;
  }
  img:nth-child(2) {
    height: 37px;
  }
}

@-webkit-keyframes STAR {
  0% {
    background: powderblue;
    height: 1px;
    width: 1px;
    box-shadow: 0px 0px 10px white;
  }
  10% {
    background: #b0e5de;
  }
  20% {
    background: #b0e5cc;
  }
  30% {
    background: #cce5b0;
  }
  40% {
    background: #dae5b0;
  }
  50% {
    background: #e5ddb0;
    width: 4px;
    height: 4px;
    box-shadow: 0px 0px 10px #d3bd3d;
  }
  100% {
    width: 1px;
    height: 1px;
    background: powderblue;
    box-shadow: 0px 0px 10px white;
  }
}


.star {
  height: 1px;
  width: 1px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px white;
  background: powderblue;
  position: absolute;
}

.star.pulse{
  -webkit-animation: STAR 2s infinite; /* Safari 4+ */
}

